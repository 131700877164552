import PropTypes from "prop-types";
import { Container, Navbar } from "react-bootstrap";
import { SiteHeaderNav } from "@/ui/SiteHeader/__components";
import clsx from "classnames";
import classes from "./styles.module.scss";
import { getRoute } from "@/functions/getRoute";
import { getGlobal } from "@/functions/getGlobal";
import { Image } from "@/ui";
import List from "@/assets/List.svg";

export default function SiteHeader({ className }) {
  const global = getGlobal();
  const company_name = global["_theme.company.name"];

  return (
    <Navbar fixed="top" collapseOnSelect expand="xl" bg="white" className={clsx(className, classes.header)}>
      <Container className="p-0 gap-4">
        <Navbar.Brand className={clsx(classes.header__brand, "me-auto px-2 py-0")} href={getRoute("home")}>
          <Image img="/chadwicknott-logo.png" size="280x66" alt={company_name} />
        </Navbar.Brand>
        <Navbar.Toggle className="me-2" aria-controls="responsive-navbar-nav">
          <div>
            <List />
          </div>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-sm-end">
          <SiteHeaderNav className={classes.header__nav} />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

SiteHeader.defaultProps = {
  className: "",
};

SiteHeader.propTypes = {
  className: PropTypes.string,
};
